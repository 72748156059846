import { IMPORT_ACCOUNTS_FAIL, START_IMPORT_ACCOUNTS } from '../../pages/ManageCardMemberPage/actions/accountAction';
import {
  API_CALL_ERROR,
  BEGIN_API_CALL,
  CLEAR_ERROR,
  SELECT_GROUP,
  SELECT_PARKING_LOT,
  UNSELECT_GROUP,
  UNSELECT_PARKING_LOT
} from '../actions/uiAction';
import { LOAD_MONTH_LOG_SUCCESS } from '../../pages/ManageCardMemberPage/actions/monthLogAction';

const initialState = {
  selectedParkingLot: null,
  selectedGroup: null,
  apiCallsInProgress: 0,
  importAccountError: null,
  apiCallError: null,
  latestLog: null
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_PARKING_LOT:
      return {
        ...state,
        selectedParkingLot: action.payload
      };
    case UNSELECT_PARKING_LOT: {
      return { ...state, selectedParkingLot: null };
    }
    case SELECT_GROUP: {
      return { ...state, selectedGroup: action.payload };
    }
    case UNSELECT_GROUP: {
      return { ...state, selectedGroup: null };
    }
    case BEGIN_API_CALL:
      return {
        ...state,
        apiCallsInProgress: state.apiCallsInProgress + 1,
        apiCallError: null
      };
    case API_CALL_ERROR:
      return {
        ...state,
        apiCallsInProgress: state.apiCallsInProgress - 1,
        apiCallError: action.payload
      };
    case START_IMPORT_ACCOUNTS: {
      return { ...state, importAccountError: null };
    }
    case IMPORT_ACCOUNTS_FAIL:
      const errorRows = action.payload;

      return {
        ...state,
        importAccountError: `Import thất bại ở dòng số ${errorRows.join(
          ', '
        )}, dữ liệu có thể bị trùng hoặc thiếu thông tin`
      };
    case CLEAR_ERROR:
      return { ...state, apiCallError: null };
    case LOAD_MONTH_LOG_SUCCESS:
      return {
        ...state,
        latestLog: action.payload
      };
    default:
      if (actionTypeEndsInSuccess(action.type) && state.apiCallsInProgress > 0) {
        return { ...state, apiCallsInProgress: state.apiCallsInProgress - 1 };
      }

      return state;
  }
}

function actionTypeEndsInSuccess(type) {
  return type.includes('_SUCCESS');
}
