import moment from 'moment';
import { apiCallError, beginApiCall } from '../../../store/actions/uiAction';
import { getLatestLog } from './accountService';

export const LOAD_MONTH_LOGS_SUCCESS = 'LOAD_MONTH_LOGS_SUCCESS';
export const LOAD_MONTH_LOG_SUCCESS = 'LOAD_MONTH_LOG_SUCCESS';

export const loadMemberLogs = (parkingLot, query) => async (dispatch, getState, { getFirebase }) => {
  try {
    dispatch(beginApiCall());

    const queryParams = ['orderByChild=created'];

    if (query) {
      const { startDate, endDate } = query;
      if (startDate) {
        queryParams.push(
          `startAt=${moment(startDate)
            .startOf('date')
            .toDate()
            .getTime()}`
        );
      }
      if (endDate) {
        queryParams.push(
          `endAt=${moment(endDate)
            .endOf('date')
            .toDate()
            .getTime()}`
        );
      }
    } else {
      queryParams.push(
        `startAt=${moment()
          .startOf('month')
          .startOf('date')
          .toDate()
          .getTime()}`,
        `endAt=${moment()
          .endOf('date')
          .toDate()
          .getTime()}`
      );
    }

    const auth = getState().firebase.auth;
    await getFirebase().watchEvent('once', `/month_logs/${auth.uid}_${parkingLot.uid}`, 'monthLogs', {
      isQuery: true,
      queryParams
    });

    dispatch({ type: LOAD_MONTH_LOGS_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch(apiCallError(e.message));
  }
};

export const loadMemberLog = (parkingLot, account, callback) => async (dispatch, getState, { getFirebase }) => {
  try {
    const auth = getState().firebase.auth;
    const latestLog = await getLatestLog({ firebase: getFirebase(), account, parkingLot, auth });

    dispatch({ type: LOAD_MONTH_LOG_SUCCESS, payload: latestLog });
    if (callback) {
      callback();
    }
  } catch (e) {
    console.log(e);
    dispatch(apiCallError(e.message));
  }
};
