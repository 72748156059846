import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { getFirebase, ReactReduxFirebaseProvider } from 'react-redux-firebase';
import firebase from './services/firebase';
import reducers from './store/reducers';
import { Provider } from 'react-redux';

import './index.css';
import App from './App';

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const store = createStore(reducers, composeEnhancers(
  applyMiddleware(thunk.withExtraArgument({ getFirebase })))
);

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider firebase={firebase} config={{}} dispatch={store.dispatch}>
      <App/>
    </ReactReduxFirebaseProvider>
  </Provider>
  , document.getElementById('root')
);
