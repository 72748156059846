export const SELECT_PARKING_LOT = 'SELECT_PARKING_LOT';
export const UNSELECT_PARKING_LOT = 'UNSELECT_PARKING_LOT';

export const SELECT_GROUP = 'SELECT_GROUP';
export const UNSELECT_GROUP = 'UNSELECT_GROUP';

export const BEGIN_API_CALL = 'BEGIN_API_CALL';
export const API_CALL_ERROR = 'API_CALL_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const selectParkingLot = parkingLot => ({
  type: SELECT_PARKING_LOT,
  payload: parkingLot
});
export const unselectParkingLot = () => ({ type: UNSELECT_PARKING_LOT });

export const selectGroup = group => ({ type: SELECT_GROUP, payload: group });
export const unselectGroup = () => ({ type: UNSELECT_GROUP });

export const beginApiCall = () => ({ type: BEGIN_API_CALL });
export const apiCallError = message => ({
  type: API_CALL_ERROR,
  payload: message
});
export const clearError = () => ({ type: CLEAR_ERROR });
