export const DateFormat = {
  DDMMYYYY: 'DD/MM/YYYY',
  VN_DATE: 'DD/MM/YYYY HH:mm'
};

export const TransportType = {
  XE_HOI: 4,
  XE_MAY: 2
};

export const PaymentMethod = {
  MONTH: 1,
  QUARTER: 3,
  CUSTOM: 0
};

export const GroupType = {
  CA_NHAN: 0,
  TO_CHUC: 1
};

export const CommandType = {
  CREATE: 0,
  EXTEND: 1,
  DELETE: 2,
  UPDATE: 3
};
