import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import uiReducer from './uiReducer';
import { SIGNOUT_SUCCESS } from '../actions/authAction';

const appReducer = combineReducers({
  firebase: firebaseReducer,
  uiReducer,
});

const rootReducer = (state, action) => {
  if (action.type === SIGNOUT_SUCCESS) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
