import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

// const firebaseConfig = {
//   apiKey: 'AIzaSyCrip8dteWwR_Shy9yfjqij-lHlkBGC2YU',
//   authDomain: 'myflutterapp-1efd0.firebaseapp.com',
//   databaseURL: 'https://myflutterapp-1efd0.firebaseio.com',
//   projectId: 'myflutterapp-1efd0',
//   storageBucket: 'myflutterapp-1efd0.appspot.com',
//   messagingSenderId: '724871024232',
//   appId: '1:724871024232:web:6e9a701a0d3de84e44b5dd'
// };

const firebaseConfig = {
  apiKey: "AIzaSyDPxpfkozbZI3St2L9y_mmLcrhbipKgzlQ",
  authDomain: "mgxbike.firebaseapp.com",
  databaseURL: "https://mgxbike.firebaseio.com",
  projectId: "mgxbike",
  storageBucket: "mgxbike.appspot.com",
  messagingSenderId: "134433103800",
  appId: "1:134433103800:web:435f7f3e52687abe50794d"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
