import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import './App.scss';
import { connect } from 'react-redux';
import LoadingSpinner from './components/UI/LoadingSpinner/LoadingSpinner';

const loading = () => <LoadingSpinner />;

// Containers
const Layout = React.lazy(() => import('./components/Layout'));

// Pages
const Login = React.lazy(() => import('./pages/LoginPage/LoginPage'));
const Page404 = React.lazy(() => import('./pages/Page404/Page404'));
const Page500 = React.lazy(() => import('./pages/Page500/Page500'));

const App = props => {
  const { auth } = props;

  let routes;
  if (auth.isEmpty) {
    routes = (
      <Switch>
        <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
        <Route exact path="/" name="Login Page" render={props => <Login {...props} />} />
        {auth.isLoaded && auth.isEmpty && <Redirect to="/" />}
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
        <Route path="/" name="Home" render={props => <Layout {...props} />} />
      </Switch>
    );
  }

  return (
    <BrowserRouter>
      <React.Suspense fallback={loading()}>{routes}</React.Suspense>
    </BrowserRouter>
  );
};

const mapStateToProps = state => ({
  auth: state.firebase.auth
});

export default connect(mapStateToProps)(App);
