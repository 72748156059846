import { apiCallError, beginApiCall } from './uiAction';

// SIGN IN
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';

// SIGN OUT
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';

export const signIn = (email, password) => async (dispatch, getState, { getFirebase }) => {
  try {
    dispatch(beginApiCall());
    await getFirebase()
      .auth()
      .signInWithEmailAndPassword(email, password);
    dispatch({ type: SIGNIN_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch(apiCallError(e));
  }
};

export const signOut = () => async (dispatch, getState, { getFirebase }) => {
  try {
    dispatch(beginApiCall());
    await getFirebase().auth().signOut();
    dispatch({ type: SIGNOUT_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch(apiCallError(e.message));
  }
};
