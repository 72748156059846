import orderBy from 'lodash/orderBy';
import { CommandType } from '../../../utils/constants';

export const loadGroupAccount = (firebase, auth, parkingLot, groupId) => {
  return firebase.watchEvent('once', `/month_account/${auth.uid}/${parkingLot.uid}`, 'accounts', {
    isQuery: true,
    queryParams: ['orderByChild=group/id', `equalTo=${groupId}`]
  });
};

export const addMonthLogVersion = async ({firebase, auth, parkingLot}) => {
  const payload = {
    member_ver: new Date().getTime()
  }
  return firebase.update(`/versions/${auth.uid}_${parkingLot.uid}`, payload);
}

export const addMonthLog = async ({ firebase, auth, parkingLot, account, cmd }) => {
  let price = account.price || 0;

  const payload = {
    active: account.activeDate,
    cmd,
    created: new Date().getTime(),
    expired: account.endDate,
    id: account.uuid || account.uid,
    owner_id: auth.uid,
    park_id: parkingLot.uid,
    payload: {
      ...account,
      uid: null,
      price: null
    },
    price: +price
  };

  return firebase.push(`/month_logs/${auth.uid}_${parkingLot.uid}`, payload);
};

export const getLatestLog = async ({ firebase, auth, parkingLot, account }) => {
  const logs = await firebase.watchEvent('once', `/month_logs/${auth.uid}_${parkingLot.uid}`, 'monthLogs', {
    isQuery: true,
    queryParams: ['orderByChild=id', `equalTo=${account.uuid || account.uid}`]
  });

  if (logs && logs.data && Object.keys(logs.data).length > 0) {
    const filteredLogs = Object.values(logs.data).filter(
      item => item.cmd === CommandType.CREATE || item.cmd === CommandType.EXTEND
    );
    const orderedLogs = orderBy(filteredLogs, ['created'], ['desc']);

    return orderedLogs[0];
  }

  return null;
};

export const uploadImage = async ({ firebase, auth, parkingLot, account, image }) => {
  // delete image before upload new one
  if (account.remotePath) {
    const path = firebase.storage().refFromURL(account.remotePath);
    if (path) {
      await firebase.deleteFile(path.fullPath);
    }
  }

  // upload new image
  const uploaded = await firebase.uploadFile(`/month_accounts/${auth.uid}/${parkingLot.uid}`, image, '', {
    name: new Date().getTime()
  });

  return uploaded.uploadTaskSnapshot.ref.getDownloadURL();
};
