import { apiCallError, beginApiCall } from '../../../store/actions/uiAction';
import { addMonthLog, loadGroupAccount, uploadImage, addMonthLogVersion } from './accountService';
import isNil from 'lodash/isNil';
import { CommandType } from '../../../utils/constants';

export const LOAD_ACCOUNTS_SUCCESS = 'LOAD_ACCOUNTS_SUCCESS';
export const ADD_ACCOUNTS_SUCCESS = 'ADD_ACCOUNTS_SUCCESS';
export const UPDATE_ACCOUNTS_SUCCESS = 'UPDATE_ACCOUNTS_SUCCESS';
export const DELETE_ACCOUNTS_SUCCESS = 'DELETE_ACCOUNTS_SUCCESS';
export const IMPORT_ACCOUNTS_SUCCESS = 'IMPORT_ACCOUNTS_SUCCESS';
export const IMPORT_ACCOUNTS_FAIL = 'IMPORT_ACCOUNTS_FAIL';
export const START_IMPORT_ACCOUNTS = 'START_IMPORT_ACCOUNTS';

export const startImportAccount = () => ({ type: START_IMPORT_ACCOUNTS });

export const loadAccounts = (parkingLot, groupId) => async (dispatch, getState, { getFirebase }) => {
  try {
    dispatch(beginApiCall());
    const auth = getState().firebase.auth;
    await loadGroupAccount(getFirebase(), auth, parkingLot, groupId);

    dispatch({ type: LOAD_ACCOUNTS_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch(apiCallError(e.message));
  }
};

export const addAccount = (parkingLot, group, account, image) => async (dispatch, getState, { getFirebase }) => {
  try {
    dispatch(beginApiCall());
    const auth = getState().firebase.auth;
    let url = null;

    const firebase = getFirebase();

    if (image) {
      url = await uploadImage({ firebase, account, parkingLot, auth, image });
    }

    const payload = {
      activeDate: account.startDate
        .startOf('date')
        .toDate()
        .getTime(),
      carId: account.carId,
      createDate: new Date().getTime(),
      endDate: account.endDate
        .endOf('date')
        .toDate()
        .getTime(),
      name: account.name,
      phone: account.phoneNumber,
      type: +account.type,
      remotePath: url,
      paymentMethod: +account.paymentMethod,
      group: {
        id: group.uid,
        name: group.name,
        description: group.description
      }
    };

    const result = await firebase.push(`/month_account/${auth.uid}/${parkingLot.uid}`, payload);

    addMonthLog({
      firebase,
      account: { ...payload, uuid: result.key, price: +account.price },
      auth,
      cmd: CommandType.CREATE,
      parkingLot
    });
    addMonthLogVersion({firebase, auth, parkingLot});

    await loadGroupAccount(firebase, auth, parkingLot, group.uid);

    dispatch({ type: ADD_ACCOUNTS_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch(apiCallError(e.message));
  }
};

export const updateAccount = (parkingLot, group, account, image) => async (dispatch, getState, { getFirebase }) => {
  try {
    dispatch(beginApiCall());
    const auth = getState().firebase.auth;
    const firebase = getFirebase();

    let url = account.remotePath || null;
    if (image) {
      url = await uploadImage({ firebase: firebase, account, parkingLot, auth, image });
    }

    const activeDate = account.startDate
      .startOf('date')
      .toDate()
      .getTime();
    const endDate = account.endDate
      .endOf('date')
      .toDate()
      .getTime();

    await firebase.update(`/month_account/${auth.uid}/${parkingLot.uid}/${account.uid}`, {
      activeDate,
      endDate,
      remotePath: url
    });

    const payload = {
      activeDate,
      carId: account.carId,
      createDate: new Date().getTime(),
      endDate,
      name: account.name,
      phone: account.phoneNumber,
      type: +account.type,
      remotePath: url,
      paymentMethod: +account.paymentMethod,
      uuid: account.uid,
      group: {
        id: group.uid,
        name: group.name,
        description: group.description
      }
    };

    addMonthLog({
      firebase,
      account: payload,
      auth,
      cmd: CommandType.UPDATE,
      parkingLot
    });
    addMonthLogVersion({firebase, auth, parkingLot});

    await loadGroupAccount(firebase, auth, parkingLot, group.uid);

    dispatch({ type: UPDATE_ACCOUNTS_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch(apiCallError(e.message));
  }
};

export const extendAccount = (parkingLot, group, account, image) => async (dispatch, getState, { getFirebase }) => {
  try {
    dispatch(beginApiCall());
    const auth = getState().firebase.auth;
    const firebase = getFirebase();

    let url = account.remotePath || null;
    if (image) {
      url = await uploadImage({ image, auth, parkingLot, account, firebase });
    }

    const activeDate = account.startDate
      .startOf('date')
      .toDate()
      .getTime();
    const endDate = account.endDate
      .endOf('date')
      .toDate()
      .getTime();

    await firebase.update(`/month_account/${auth.uid}/${parkingLot.uid}/${account.uid}`, {
      activeDate,
      endDate,
      remotePath: url,
      paymentMethod: +account.paymentMethod
    });

    const payload = {
      activeDate,
      carId: account.carId,
      createDate: new Date().getTime(),
      endDate,
      name: account.name,
      phone: account.phoneNumber,
      type: +account.type,
      remotePath: url,
      paymentMethod: +account.paymentMethod,
      uuid: account.uid,
      group: {
        id: group.uid,
        name: group.name,
        description: group.description
      },
      price: +account.price
    };

    addMonthLog({
      firebase: firebase,
      account: payload,
      auth,
      cmd: CommandType.EXTEND,
      parkingLot
    });
    addMonthLogVersion({firebase, auth, parkingLot});

    await loadGroupAccount(firebase, auth, parkingLot, group.uid);

    dispatch({ type: UPDATE_ACCOUNTS_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch(apiCallError(e.message));
  }
};

export const deleteAccount = (parkingLot, account, group) => async (dispatch, getState, { getFirebase }) => {
  try {
    dispatch(beginApiCall());
    const { auth } = getState().firebase;
    const firebase = getFirebase();

    // delete account
    await firebase.remove(`/month_account/${auth.uid}/${parkingLot.uid}/${account.uid}`);

    if (account.remotePath) {
      // delete image
      const path = firebase.storage().refFromURL(account.remotePath);
      if (path) {
        await firebase.deleteFile(path.fullPath);
      }
    }
    addMonthLog({
      firebase,
      account: { ...account, uuid: account.uid },
      auth,
      cmd: CommandType.DELETE,
      parkingLot
    });
    addMonthLogVersion({firebase, auth, parkingLot});

    await loadGroupAccount(firebase, auth, parkingLot, group.uid);

    dispatch({ type: DELETE_ACCOUNTS_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch(apiCallError(e.message));
  }
};

function validAccount(account, existingAccounts, newAccounts) {
  let isValid = Object.keys(account).every(key => !isNil(account[key]));

  if (existingAccounts && Object.keys(existingAccounts).length > 0) {
    if (Object.values(existingAccounts).some(value => value.phone === account.phone || value.carId === account.carId)) {
      isValid = false;
    }
  }

  if (newAccounts.filter(item => item.phone === account.phone || item.carId === account.carId).length > 1) {
    isValid = false;
  }

  if (account.endDate < account.activeDate) {
    isValid = false;
  }

  return isValid;
}

export const importAccounts = (parkingLot, accounts, group) => async (dispatch, getState, { getFirebase }) => {
  try {
    dispatch(beginApiCall());
    const auth = getState().firebase.auth;
    const existingAccounts = getState().firebase.data.accounts;
    const firebase = getFirebase();  
    const errorRows = [];
    let row = 2;

    for (let account of accounts) {
      if (validAccount(account, existingAccounts, accounts)) {
        const payload = {
          ...account,
          price: null,
          group: {
            id: group.uid,
            name: group.name,
            description: group.description
          }
        };

        const result = await firebase.push(`/month_account/${auth.uid}/${parkingLot.uid}`, payload);

        addMonthLog({
          firebase: firebase,
          account: { ...payload, uuid: result.key, price: account.price },
          auth,
          cmd: CommandType.CREATE,
          parkingLot
        });
      } else {
        errorRows.push(row);
      }
      row++;
    }

    // if errorRows length > 0, display row index on UI.
    if (errorRows.length > 0) {
      dispatch({ type: IMPORT_ACCOUNTS_FAIL, payload: errorRows });
    }
    addMonthLogVersion({firebase, auth, parkingLot});

    await loadGroupAccount(getFirebase(), auth, parkingLot, group.uid);
    dispatch({ type: IMPORT_ACCOUNTS_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch(apiCallError(e.message));
  }
};
